import { User as DatadogUser } from "@datadog/browser-core";
import { datadogRum } from "@datadog/browser-rum";

import packageInfo from "src/../package.json";

export function initDatadog() {
  const queryParams = new URLSearchParams(window.location.search);
  const skipRum = queryParams.get("skipRum");

  if (skipRum) {
    console.debug("Skipping Datadog init due to skipRum query param");
    return;
  } else if (import.meta.env.VITE_ENV_TYPE !== "production" && import.meta.env.VITE_ENV_TYPE !== "staging") {
    console.debug("Skipping Datadog init in non-production environment");
    return;
  }
  // Initialize Datadog RUM with same settings as rollup-web
  datadogRum.init({
    applicationId: "e165421c-d2f4-4954-b2ef-055e93710a04",
    clientToken: "pub4b963783d28e424030031bbcce9852c4",
    proxy: "https://analytics.rollup.ai/datadog",
    site: "datadoghq.com",
    service: "rollupweb",
    env: import.meta.env.VITE_ENV_TYPE ?? "debug",
    version: packageInfo?.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

// unused in rollup-auth but left so this file is the same as in rollup-web
export function setDataDogUser(user: DatadogUser) {
  datadogRum.setUser(user);
}
